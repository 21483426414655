import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import { Menu } from "semantic-ui-react";

import Tiles from "../../components/tiles/Tiles";
import Pages from "../../components/pages/Pages";
import Structure from "../../components/structure/Structure";
import JunctionPage from "../../components/junctionPage";
import Templates from "../../components/templates";
import Images from "../../components/imageManagement";
const Index = () => {
  const [activeItem, update_activeItem] = useState("structure");

  function renderSections() {
    switch (activeItem) {
      case "tiles":
        return <Tiles />;
        break;
      case "pages":
        return <Pages />;
        break;
      case "structure":
        return <Structure />;
        break;
      case "junction":
        return <JunctionPage />;
      case "templates":
        return <Templates />;
      case "images":
        return <Images />;
      default:
        break;
    }
  }

  return (
    <Layout>
      <Menu>
        <Menu.Item
          name="structure"
          active={activeItem === "structure"}
          onClick={(e, { name }) => update_activeItem(name)}
        >
          Structure
        </Menu.Item>
        <Menu.Item
          name="tiles"
          active={activeItem === "tiles"}
          onClick={(e, { name }) => update_activeItem(name)}
        >
          Tiles
        </Menu.Item>

        <Menu.Item
          name="pages"
          active={activeItem === "pages"}
          onClick={(e, { name }) => update_activeItem(name)}
        >
          Pages
        </Menu.Item>
        <Menu.Item
          name="junction"
          active={activeItem === "junction"}
          onClick={(e, { name }) => update_activeItem(name)}
        >
          Junction Pages
        </Menu.Item>
        <Menu.Item
          name="templates"
          active={activeItem === "templates"}
          onClick={(e, { name }) => update_activeItem(name)}
        >
          Templates
        </Menu.Item>
        <Menu.Item
          name="images"
          active={activeItem === "images"}
          onClick={(e, { name }) => update_activeItem(name)}
        >
          Images
        </Menu.Item>
      </Menu>
      {renderSections()}
    </Layout>
  );
};

export default Index;
