import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchPathways, fetchJunctionPages } from '../../actions';

import _ from 'lodash'

import { Header, Segment, Button, Divider } from 'semantic-ui-react'

import JunctionPagesList from './JunctionPagesList'
import JunctionPageNew from './JunctionPageNew'
const JunctionPage = () => {
    const [loading, update_loading] = useState(true)
    const dispatch = useDispatch();
    const pathways = useSelector(state => state.pathways);
    const pages = useSelector(state => state.pages);
    const junctionPages = useSelector(state => state.junctionPages);
    const user = useSelector(state => state.user);

    const [redirect_data, updateRedirectData]= useState([])
    const [selectedPathway, updateSelectedPathway] = useState("")

    useEffect(() => {
        if(loading === true){
            console.log(junctionPages)
            dispatch(fetchPathways(user['custom:trust']))
            dispatch(fetchJunctionPages(user['custom:trust']))
            update_loading(false)
        }
      }, []);

    return (
        <div>
        {loading ? (
            <div>loading</div>
        ):(
            <div>
                 <Header style={{marginTop: 30}} as='h2'>Junction Pages</Header>
                <Segment style={{textAlign: 'center', background: '#efefef', width: '600px'}} color='blue' >
                <JunctionPageNew user={user}/>
                </Segment>
                <JunctionPagesList 
                    junctionPages={junctionPages} 
                    //user={user['custom:trust']} 
                    // user_access={user['custom:level']}
                />
            </div>
        )}
        </div>
    )
}

export default JunctionPage
