import React, { useState, useEffect } from "react";
import {
  Header,
  List,
  Card,
  Icon,
  Loader,
  Dropdown,
  Segment,
  Button,
  Grid,
  Divider,
} from "semantic-ui-react";
import { Link } from "gatsby";

import { useDispatch, useSelector } from "react-redux";
import { fetchTemplates } from "../../actions";

import PagesList from "../pages/PagesList";

const Index = () => {
  const dispatch = useDispatch();

  const templates = useSelector((state) => state.templates);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(fetchTemplates());
    console.log("templates");
    console.log(templates);
  }, []);

  return (
    <div>
      <PagesList
        pages={templates}
        //page_select={this.updatePageSelect}
        user={user["custom:trust"]}
        user_access={user["custom:level"]}
      />
    </div>
  );
};

export default Index;
